import React from 'react'
import cx from 'classnames'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/oleje-jak-je-pouzivat-v-kuchyni.jpg'
import styles from './post-grid.module.css'

const OlejeJakPouzivatVKuchyni = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Oleje - jak je používat v kuchyni? | NutritionPro'}
        description={
          'Zdravá výživa je velmi důležitým aspektem životního stylu každého z nás. Jedním z klíčových prvků v této oblasti jsou oleje. Ty se využívají v kuchyni jak při vaření, tak ve studených pokrmech. Existuje však mnoho různých druhů olejů, a ne každý z nich je vhodný pro kteroukoliv úpravu. V tomto článku se proto podíváme na oleje, které se používají pro tepelnou úpravu a také na ty, které jsou vhodné pro studenou kuchyni. Také zjistíte, které z nich jsou (ne)zdravé a jaký mají vliv na vaše zdraví.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/oleje-jak-je-pouzivat-v-kuchyni">
            Oleje - jak je používat v kuchyni?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Oleje - jak je používat v kuchyni?"
              date="16.04.2023"
            />
            <div>
              <p className={styles.postText}>
                Zdravá výživa je velmi <b>důležitým aspektem</b> životního stylu
                každého z nás. Jedním z klíčových prvků v této oblasti jsou
                oleje. Ty se využívají v kuchyni jak při vaření, tak ve
                studených pokrmech. Existuje však{' '}
                <b>mnoho různých druhů olejů</b>, a{' '}
                <b>ne každý z nich je vhodný</b> pro kteroukoliv úpravu. V tomto
                článku se proto podíváme na oleje, které se používají pro
                tepelnou úpravu a také na ty, které jsou vhodné pro studenou
                kuchyni. Také zjistíte, <b>které z nich jsou (ne)zdravé</b> a
                jaký mají vliv na vaše zdraví.
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="dívka s dítětem"
              />

              <h5 className={styles.postTitle}>Panenské a rafinované oleje</h5>
              <p className={styles.postText}>
                Nejprve si musíme říci,{' '}
                <b>které oleje jsou určené na tepelné zpracování</b> a které do{' '}
                <b>studené kuchyně</b>. Záleží totiž na tom,{' '}
                <b>jakou technologií se oleje vyrábí</b>. Oleje můžeme rozdělit
                na <b>panenské a rafinované</b>.
              </p>

              <p className={styles.postText}>
                <b>Panenské oleje</b> jsou vyráběny pouze{' '}
                <b>mechanickým lisováním nebo extrakcí</b> plodů rostlin.
                Finálním produktem jsou surové lisované nebo extrahované oleje.
                Při výrobě takovýchto olejů{' '}
                <b>
                  nedochází k procesu rafinace a nejsou použita žádná chemická
                  rozpouštědla,
                </b>{' '}
                což znamená, že si oleje zachovávají svou přirozenou chuť, vůni
                a výživovou hodnotu. Mezi nejznámější panenské oleje patří{' '}
                <b>olivový, lněný nebo řepkový</b> olej, ale seženete také
                dýňový, světlicový nebo olej z vlašských ořechů.
              </p>

              <p className={styles.postText}>
                <b>Rafinované oleje</b> jsou vyráběny z plodů rostlin pomocí{' '}
                <b>chemické či fyzikální rafinace</b>, kde dochází k{' '}
                <b>
                  čištění oleje a odstranění nečistot a dalších nežádoucích
                  látek
                </b>
                . Tento proces zpracování oleje odstraňuje většinu přírodních
                prvků, které jsou přítomny v panenských olejích, jako jsou{' '}
                <b>antioxidanty a vitamíny</b>, což znamená, že rafinované oleje
                nemají tak vysokou výživovou hodnotu. To však platí jen tehdy,
                používáte-li panenské oleje ve studené kuchyni (případně na
                krátké restování). Při tepelné úpravě se totiž přepálí žádoucí
                látky, kvůli kterým jste si olej koupili, a mohou naopak vznikat
                látky zdraví škodlivé. Naopak rafinované oleje jsou upraveny
                tak, aby se <b>mohli bezpečně využít pro tepelnou úpravu.</b>{' '}
                Mezi nejznámější rafinované oleje patří{' '}
                <b>slunečnicový, řepkový</b> nebo <b>sójový</b> olej, ale může
                to být například i olivový.
              </p>

              <p className={styles.postText}>
                Z hlediska použití v kuchyni se panenské oleje používají hlavně
                pro přípravu <b>studených jídel</b>, jako jsou saláty, omáčky
                nebo marinády. Rafinované oleje jsou na druhé straně vhodné{' '}
                <b>pro použití při vysokých teplotách</b>, například při vaření
                nebo smažení, protože mají{' '}
                <b>vyšší bod kouření a nepřepalují se</b> tak rychle.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  K olejům se váže mnoho mýtů. Podívejte se,{' '}
                  <a
                    href="https://nutritionpro.cz/blog/jak-rozpoznat-vyzivovy-mytus/"
                    target="_blank"
                  >
                    jak takový mýtus odhalit!
                  </a>
                </i>
              </p>

              <h5 className={styles.postTitle}>Jak vybrat ten správný olej?</h5>
              <p className={styles.postText}>
                Dnes je na trhu <b>obrovské množství olejů</b> z různých plodin,
                ořechů a semínek. <b>Ne každý má stejné složení</b> a ne všechny
                prošly <b>stejnou technologickou úpravou</b>. Pojďme se podívat
                na několik základních tuků a olejů a říct si, do jaké kuchyně
                jsou vhodné. Rozhodující je u olejů již zmíněný{' '}
                <b>bod zakouření</b>, což je teplota, kdy se z oleje{' '}
                <b>začne kouřit</b> (proto bod zakouření). Znamená to, že se{' '}
                <b>začíná přepalovat a stává se nestabilním</b>. Při přepalování
                začínají vznikat látky <b>škodlivé pro lidské zdraví</b>, není
                proto radno výběr vhodného oleje podceňovat.
              </p>

              <h5 className={styles.postTitle}>Rostlinné oleje</h5>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Řepkový
              </h6>
              <p className={styles.postText}>
                Řepkový olej je v dnešní době{' '}
                <b>jeden z nejvhodnějších olejů</b>, který můžete použít{' '}
                <b>při tepelné úpravě</b>. Bod zakouření řepkového oleje je cca
                230 stupňů, takže se tak snadno nepřepálí. Navíc obsahuje{' '}
                <b>
                  ideální poměr omega 3 a omega 6 esenciálních mastných kyselin
                </b>
                . Je{' '}
                <b>vhodný jak na tepelnou úpravu, tak i do studené kuchyně.</b>
              </p>

              <p className={styles.postText}>
                Dnes se řepkový olej používá hojně,{' '}
                <b>dříve se však téměř nevyužíval</b>. Před rokem 1975
                obsahovala řepka velké množství <b>kyseliny erukové</b>, která
                má negativní vliv na činnost srdečního svalu. Dnes se však
                pěstuje řepka <b>bezeruková</b>, takže je používání řepkového
                oleje <b>bezpečné</b> a naopak velmi výhodné.
              </p>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Slunečnicový
              </h6>
              <p className={styles.postText}>
                Slunečnicový olej má také <b>poměrně vysoký bod zakouření</b>, a
                to cca 200 stupňů, nicméně už{' '}
                <b>nemá tak dobrý poměr omega 3 a 6 mastných kyselin,</b> jako
                olej řepkový. Pokud tedy váháte, který z nich koupit,{' '}
                <b>volte řepkový olej</b>. Pokud však už máte slunečnicový olej
                doma, nemusíte se bát a můžete ho v klidu dopoužívat.{' '}
                <b>Nic hrozného se nestane.</b>
              </p>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Olivový
              </h6>
              <p className={styles.postText}>
                Olivový olej je asi nejvíce využívaným olejem{' '}
                <b>ve studené kuchyni</b>. Bohužel je velmi často využíván i na
                smažení a jinou tepelnou úpravu, a to i ten panenský. Olivový
                olej obsahuje <b>vitaminy rozpustné v tucích</b>, jako je
                vitamin E, a <b>antioxidanty</b>. Je poměrně stabilní, teploty
                bodu zakouření se liší podle toho, zda je olej panenský či
                rafinovaný. “Oliváč” je vhodný{' '}
                <b>do studené kuchyně i na šetrnou tepelnou úpravu</b>, tedy na
                restování, nikoliv smažení či fritování. Smažením panenského
                olivového oleje <b>zničíte veškeré prospěšné látky</b>, které
                byly díky šetrné úpravě oleje zachovány, za což si koneckonců i{' '}
                <b>připlácíte</b>. Mohou pak vznikat látky, které naopak ve svém{' '}
                <b>jídle určitě mít nechcete.</b>
              </p>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Kokosový
              </h6>
              <p className={styles.postText}>
                Ačkoliv můžete kokosový olej najít{' '}
                <b>v mnoha “fit” receptech</b>, je to úplně{' '}
                <b>nejméně vhodný olej</b>, který můžete zvolit. Zhruba 90 %
                kokosového oleje tvoří <b>nasycené mastné kyseliny</b>, jejichž
                přemíra může mít <b>negativní vliv na lidské zdraví</b> a
                zvyšovat rizika různých onemocnění, jako jsou například
                kardiovaskulární onemocnění. Kokosový olej sice{' '}
                <b>zvyšuje hladinu HDL cholesterolu</b> (“hodný” cholesterol){' '}
                <b>ale i LDL cholesterolu</b> (“zlý” cholesterol), rozhodně jej
                tedy nemůžeme vnímat jako tuk zdravý a měl by se v jídelníčku
                objevovat <b>výjimečně</b>.
              </p>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Palmový
              </h6>
              <p className={styles.postText}>
                Palmový tuk je velmi kontroverzní, a to především kvůli{' '}
                <b>etickým a environmentálním aspektům</b>. Od těch se dnes
                oprostíme a představíme si ho <b>pouze z nutričního hlediska</b>
                . Palmový olej je <b>hojně využíván v potravinářství</b>, a to
                hlavně díky jeho technologickým vlastnostem, při pokojových
                teplotách je tuhý a taje až při tepelném zpracování. Přidává se
                do těst, polev, krémů, náplní…vlastně by se dalo říct, že ho{' '}
                <b>najdete skoro ve všem</b>. Díky jeho <b>tepelné stabilitě</b>{' '}
                se tento olej dá využít i na smažení. Složením je{' '}
                <b>podobný máslu</b>, proto by se v jídelníčku měl vyskytovat
                spíše <b>minoritně</b>, a to i z toho důvodu, že jej obvykle{' '}
                <b>obsahují výrobky</b>, které by se ve vašem jídelníčku měly
                též objevovat spíše <b>svátečně</b>, jako jsou různé{' '}
                <b>cukrovinky</b>. Nicméně palmový olej v některých výrobcích{' '}
                <b>nahradil částečně ztužené tuky</b>, které se používaly dříve.
                Oproti částečně ztuženým tukům, které jsou zdrojem trans
                nenasycených mastných kyselin, které působí aterogenně, je z
                nutričního hlediska palmový olej mnohem <b>lepší variantou</b>.
              </p>

              <h5 className={styles.postTitle}>Živočišné tuky</h5>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Máslo
              </h6>
              <p className={styles.postText}>
                Máslo má bod zakouření okolo 175 stupňů, jenže při smažení se
                dostáváme na teploty okolo 200 stupňů a výš. Máslo je proto{' '}
                <b>
                  vhodné pouze do studené kuchyně, případně na šetrnou tepelnou
                  úpravu
                </b>
                . Na <b>pečení</b> je máslo v pořádku, vnitřek koláče či bábovky
                se nikdy nedostane na takovou teplotu, na kterou je trouba
                nastavená, nemusíte se proto bát, že by se přepalovalo. V
                jídelníčku by se máslo mělo objevovat <b>výjimečně</b>, protože
                obsahuje velké množství{' '}
                <b>nasycených mastných kyselin a cholesterol</b>.
              </p>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Ghí
              </h6>
              <p className={styles.postText}>
                Ghí je přepuštěné máslo, tedy{' '}
                <b>mléčný tuk bez vody a bílkovin</b>. Má vysoký podíl
                cholesterolu a nasycených mastných kyselin, stejně jako klasické
                máslo. Pokud si myslíte, že je ghí zdravá varianta másla, nebo
                má dokonce nějaké zdravotní benefity, musím vás zklamat.{' '}
                <b>Žádná studie zdravotní benefity ghí nepotvrdila.</b> Opět by
                se ve vašem jídelníčku mělo vyskytovat svátečně.
              </p>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Sádlo
              </h6>
              <p className={styles.postText}>
                Sádlo má poměrně vysoký bod zakouření, proto se{' '}
                <b>nepřepaluje tak rychle jako máslo</b>. Obsahuje však{' '}
                <b>velké množství cholesterolu</b>, který se při tepelné úpravě
                mění na <b>oxidovaný cholesterol</b> a ten je aterogenní, což
                znamená, že <b>negativně ovlivňuje srdečně-cévní systém</b>. Též
                by se proto mělo v jídelníčku objevovat <b>výjimečně.</b>
              </p>

              <h6 className={cx(styles.postSubTitle, styles.postSubTitleBold)}>
                Rybí olej
              </h6>
              <p className={styles.postText}>
                Takovou specialitou je rybí olej, který se v klasické české
                domácnosti <b>zas tak často nevyskytuje</b>. Rybí olej se
                obvykle používá ve <b>studené kuchyni</b>, a to do omáček či
                salátů. Nejčastěji se však olej z tučných ryb používá v rámci{' '}
                <b>suplementace omega 3 mastných kyselin</b>, které jsou zdraví{' '}
                <b>velmi prospěšné.</b>
              </p>

              <p className={styles.postText}>
                <b>TIP:</b>{' '}
                <i>
                  Rybí tuk je také bohatým zdrojem{' '}
                  <a
                    href="https://nutritionpro.cz/blog/vitamin-D/"
                    target="_blank"
                  >
                    vitaminu D.
                  </a>
                </i>
              </p>

              <h5 className={styles.postTitle}>Závěrem</h5>
              <p className={styles.postText}>
                Výběr správného oleje do kuchyně může mít <b>významný dopad</b>{' '}
                na vaše zdraví a celkový životní styl. Záleží na tom,{' '}
                <b>jaký olej si vyberete</b> a jak ho používáte. Některé oleje
                jsou vhodné pro vaření či smažení, zatímco jiné jsou lepší pro
                použití ve studené kuchyni. Stejně tak je možné některé oleje
                používat <b>každý den</b> a některé jen velmi <b>výjimečně</b>.
                Je důležité si uvědomit, že všechny oleje mají{' '}
                <b>odlišné vlastnosti</b> a mohou mít odlišný vliv na vaše
                zdraví, proto byste se měli při výběru oleje řídit nejen jeho
                chutí, ale také jeho <b>složením a zdravotními benefity</b>.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default OlejeJakPouzivatVKuchyni
